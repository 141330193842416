html, body 
  height: 100%

body 
  margin: 0
  font-family: Roboto, "Helvetica Neue", sans-serif
  background: #F2F2F2

.dialog-header
    color: white
    background: var(--app-primary-color)
    padding: 10px
    display: flex
    justify-content: space-between
    height: var(--dialog-header-height)
    
    mat-icon
        color: white
        cursor: pointer
/* You can add global styles to this file, and also import other style files */
// abstracts
// @import './assets/global-styles/abstracts/colors'
// @import './assets/global-styles/abstracts/font-family'
// @import './assets/global-styles/abstracts/font-size'

// base
// @import './assets/global-styles/base/base'

// material theme
@import '@angular/material/prebuilt-themes/indigo-pink.css'

// @import 'ngx-toastr/toastr';
// @import 'ngx-toastr/toastr-bs4-alert';

// @import 'ngx-toastr/toastr-bs4-alert';
// // boostrap 5
// @import 'ngx-toastr/toastr-bs5-alert';

@font-face
  font-family: "Inter"
  src: url('./assets/font/Inter-VariableFont_slnt,wght.ttf')

@font-face
  font-family: "Inter-regular"
  src: url('./assets/font/Inter-Regular.ttf')

@font-face
  font-family: "Inter-medium"
  src: url('./assets/font/Inter-Medium.ttf')

:root
  --app-main-color-original: red
  --app-main-color: var(--app-main-color-original)
  --app-main-font-size: 14px
  --font-label-size: 12px
  --font-title-size: 24px
  --font-sub-title-size: 18px
  --font-weight-semi-bold: 600
  --app-accent-color: #FDD835
  --app-main-component-color: #2C2C2C
  --app-main-button-primary-bkg-color: #2C2C2C
  --app-main-button-primary-font-color: #FFFFFF
  --app-main-button-secondary-bkg-color: #595959
  --app-main-button-secondary-font-color: #FFFFFF
  --app-data-table-action-button-color: var(--app-accent-color)
  --app-data-red-color: #DC0408
  --app-data-table-action-button-danger-color: var(--app-main-color)
  --app-data-table-filter-textbox-color: lightgrey
  --app-data-table-header-background: #F9F9F9
  --app-data-table-header-cell-color: #2C2C2C
  --app-data-card-list-label-color: #BABABA
  --app-data-card-list-value-color: #2C2C2C
  --app-data-card-list-border-color: #E3E9EF
  --app-icon-button-background-color: #2C2C2C
  --app-font-family: "Inter"
  --app-background-color: #EFF1F3
  --app-tooltip-message-size: 13px
  --bs-navbar-brand-hover-color: red
  --bs-navbar-brand-hover-color: green
  --app-scrollbar-width: 0px
  --app-moz-scrollbar-width: thin
  --app-moz-menu-scrollbar-width: none
  --app-main-expansion-color: #E3E9EF
  scrollbar-width: var(--app-moz-scrollbar-width) !important

body
  font-family: var(--app-font-family) !important
  background-color: var(--app-background-color) !important



// SCROLLBAR YELLOW
//------------------

/* width */
// ::-webkit-scrollbar
//   width: 10px


// /* Track */
// ::-webkit-scrollbar-track
//   box-shadow: inset 0 0 8px var(--app-main-component-color)
//   border-radius: 10px
//   // background-color: var(--app-main-component-color)


// /* Handle */
// ::-webkit-scrollbar-thumb
//   background: var(--app-data-table-action-button-color)
//   border-radius: 10px


// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover
//   background: #e1c100

//----------------------
//----------------------

// SCROLLBAR THIN GREY
//----------------------

/* width */
::-webkit-scrollbar
  width: var(--app-scrollbar-width)


[dir=rtl] ::-webkit-scrollbar
  direction: rtl

/* Track */
// ::-webkit-scrollbar-track
  // box-shadow: inset 0 0 8px var(--app-main-component-color)
  // border-radius: 10px
  // background-color: var(--app-main-component-color)


/* Handle */
// ::-webkit-scrollbar-thumb
//   background-color: var(--app-main-button-secondary-bkg-color)
//   border-radius: 10px


/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover
  // background: #e1c100

//----------------------
//----------------------


// html, body
//   height: 100%


.mat-slide-toggle-label
  font-size: 14px !important

.mat-tooltip
  font-size: var(--app-tooltip-message-size) !important

[dir=rtl] .mat-form-field-appearance-outline .mat-form-field-outline-start
  border-left-style: solid !important
  border-right-style: none !important
  border-radius: 5px 0 0 5px !important

[dir=rtl] .mat-form-field-appearance-outline .mat-form-field-outline-end
  border-right-style: solid !important
  border-left-style: none !important
  border-radius: 0 5px 5px 0 !important

[dir=rtl] .mat-form-field-appearance-outline .mat-form-field-outline
  flex-direction: row-reverse !important

[dir=rtl] .mat-dialog-container
  direction: rtl !important


.mat-appearence-none .mdc-notched-outline__trailing
  border: none

.mat-appearence-none .mdc-notched-outline__notch
  border: none

.mat-appearence-none .mdc-notched-outline__leading
  border: none

.mat-form-field-infix
  width: auto !important
//   display: flex !important

.alert-box-action-button
    border: 2px solid var(--app-data-table-action-button-color) !important
    min-width: 80px
    height: fit-content
    align-self: center
    background-color: #FFFFFF !important
    color: var(--app-data-table-action-button-color) !important
    box-shadow: none !important


.alert-box-action-button:hover
    color: #FFFFFF !important
    background-color: var(--app-data-table-action-button-color) !important

.mat-form-field
  font-size: var(--app-main-font-size)
  font-family: var(--app-font-family) !important

.mat-form-field-appearance-fill .mat-form-field-flex
  padding-top: 0px !important

.mat-slide-toggle-content
  font-family: var(--app-font-family) !important

.button-flat
  border: none
  background: none

.flat-input.ng-touched.ng-invalid
  border: 1px solid red
  border-radius: 10px
  padding: 0px 15px

.icon-round-button
  background-color: var(--app-icon-button-background-color)
  border-radius: 30px
  height: 45px
  width: 45px

.white-search-icon
  fill: #FFFFFF
  width: 24px

.app-button
  color: #FFFFFF
  height: 35px
  border-radius: 5px
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x)
  font-family: var(--bs-btn-font-family)
  font-size: var(--bs-btn-font-size)
  font-weight: var(--bs-btn-font-weight)
  line-height: var(--bs-btn-line-height)

.app-button-primary
  background-color: var(--app-main-button-primary-bkg-color) !important
  color: var(--app-main-button-primary-font-color) !important

.app-button-secondary
  background-color: var(--app-main-button-secondary-bkg-color) !important
  color: var(--app-main-button-secondary-font-color) !important

.tooltip-input-container
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-items: center

.full-width-element
  width: 100%

.flex-row
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-items: center

.mat-table
  border-radius: 8px 8px 0px 0px

.mat-header-row
  background: var(--app-data-table-header-background)
  border-radius: 8px 8px 0px 0px

.mat-header-cell
  color: var(--app-data-table-header-cell-color)
  font-family: var(--app-font-family)
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 17px
  display: flex
  align-items: center

.mat-row
  cursor: pointer

.divider
  color: #bfbfbf

.error-message
  color: red
  font-size: 13px

// GENERAL SLIDE TOOGLE CONFIGURATION:
//------------------------------------

// design

.no-padding
  padding: 0px !important

.no-margin
  margin: 0px !important

// primary button
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary
  background-color: var(--app-data-table-action-button-color)
  color: #000000

// this sets the color and weight of all the slide toogle labels in the application
.slide-toogle-label
  align-self: center
  font-weight: 400
  color: #9d9d9d //#757575

.slide-toogle-label-danger
  align-self: center
  font-weight: 400
  color: #9d9d9d //#757575

// this sets the general color of all slide toogles in the application
// when checked
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar
  background-color: var(--app-data-table-action-button-color) !important
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb
  background-color: #FFFFFF !important
// when unchecked
.mat-slide-toggle-bar
  background-color: #d7d7d7 !important //#868686 !important
.mat-slide-toggle-thumb
  background-color: #FFFFFF !important
  height: 16px !important
  width: 16px !important

.title-box
  margin-inline-end: 5%

  .mat-form-field-wrapper
    padding: 0
  .mat-form-field-appearance-outline .mat-form-field-wrapper
    padding: 0
  .mat-form-field-label-wrapper
    font-size: var(--app-main-font-size)

// when not editable
.dimmed-color-slide-toogle.mat-slide-toggle-label .mat-slide-toggle-bar
  opacity: 0

.mat-slide-toggle-bar
  height: 20px !important
  width: 36px !important
  border-radius: 11px !important

.mat-slide-toggle-thumb-container
  width: 18px !important
  height: 18px !important
  top: 2px !important
  left: 2px !important

// CUSTOM DESIGN
div:has(.notification-height)
  max-height: 80VW

.notification-height
  background-color: var(--app-background-color) !important
// .survey-text-box

// .flat-text-box .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-appearance-outline .mat-form-field-outline-start
//   border: none

// .flat-text-box .mat-form-field-appearance-outline .mat-form-field-outline-gap
//   border: none


.locate-text
  margin: 0px 5px

.bottom-border
  border-bottom: 2px solid
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-items: center

.button-flex
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-items: center



.benef-score-level
  display: flex
  justify-content : center
  flex-direction : column
  align-items : center
  height:70px
  width:70px
  border-radius: 40px
  border: 5px solid
  margin: 5px
  padding: 3px
  text-align: center


.wrapped-flex
  display: flex
  flex-wrap: wrap
  justify-content: center

@media screen and (max-width: 1530px)
  .benef-score-level
      display: flex
      justify-content: center
      flex-direction: column
      align-items: center
      height: 60px
      width: 60px
      border-radius: 30px
      border: 5px solid
      margin: 5px
      font-size: 10px
      padding: 3px
      text-align: center

@use './assets/global-styles/_variables' as _variables
@import './app/siren_survey_web/src/styles'
@import './app/siren_admin_web/src/assets/global-styles/base/_base'

:root
  --menu-height:60px
  --app-primary-color: var(--app-main-component-color)
  --dialog-header-height: 45px

.mat-expansion-panel
  margin: 5px 0 !important

.march-page-title
  font-weight: 600
  font-size: var(--font-title-size)
  line-height: 29px

.title-container
  display: flex
  flex-direction: row
  padding: 10px 0px
  justify-content: space-between
  align-items: center

.sub-title
  div
   display: flex
   align-items: center
   font-weight: 600
   font-size: 20px




.btn-submit
  background-color: var(--app-accent-color)
  color: var(--app-main-component-color)
  font-weight: 600

.locateForm
  padding: 10px 2%

.fullWidth
  width: 100%


.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element
    opacity: 0.03 !important
    background-color: #249C65 !important

.expansion-panel-header
  height: auto
  min-height: _variables.$expansion-panel-height
  background-color: var(--app-main-expansion-color)

.dialog-container
  margin:20px
.dialog-button-container
  display: flex
  justify-content: flex-end

@media screen and (max-width: 575px)
    .title-container
      text-align: center


.locate-text
  margin: 0px 5px

.bottom-border
  border-bottom: 2px solid
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  align-items: center

.invalid-control > mat-form-field > .mdc-text-field--no-label
  border: 1px solid red
  border-radius: 10px

.search-input div.mat-select-search-inner
  background-color: white

